html, body {
    height: 100%;
  }
.landing{
    height: 100%;
    .firstPlane{
        
        height: 100%;
        /* Create the parallax scrolling effect */
        background-color: #1e1e1e;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;

        .row{
            margin: unset;
            height: 5%;
        }
        
        .star {
            width: 2%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
}